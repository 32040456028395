.table-cell-menu {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  position: relative;
  user-select: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }

  div.overflow-menu {
    position: absolute;
    top: 35px;
    left: 0;
    display: none;

    ul {
      position: fixed;
      z-index: 10;
      padding-left: 0;
      width: 100px;
      background-color: white;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      overflow: hidden;

      li {
        padding: 10px;

        &:hover {
          background-color: #efefef;
        }
      }

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
